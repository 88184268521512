<template>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <ion-icon
        v-show="!isSpeaking"
        @click.prevent="testVoice"
        class="voice-change-icon mr-4 mt-2 text-muted btn btn-default"
        :icon="playOutline"
      >
      </ion-icon>
      <ion-icon
        v-show="isSpeaking"
        @click.prevent="testVoice"
        class="voice-change-icon mr-4 mt-2 text-muted btn btn-default"
        :icon="pauseOutline"
      >
      </ion-icon>
    </div>
    <select
      v-model="voice"
      :style="settings.fg"
      class="form-select form-control text-center"
      aria-label="Default select example"
      placeholder="Select a voice"
      
    >
      <option>Select a voice </option>
      <option v-for="voice in voices" :key="voice.name" :value="voice"
        >{{ displayName(voice)}}</option
      >
    </select>
    <div class="input-group-append">
      <ion-icon
        @click.prevent="updateHyacinthSelectedVoice"
        class="voice-change-icon mr-4 mt-2 text-muted btn btn-default"
        :icon="checkmarkOutline"
      >
      </ion-icon>
    </div>
  </div>
</template>

<script>
import { IonIcon, IonSelect, IonSelectOption, IonLabel } from "@ionic/vue";
import { createNamespacedHelpers } from "vuex";
import { playOutline, checkmarkOutline, pauseOutline } from "ionicons/icons";

const HyacinthModule = createNamespacedHelpers('hyacinth')
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "VoiceChanger",
  components: {
    IonIcon,
    // IonLabel,
    // IonSelect,
    // IonSelectOption,
    // IonItem,
  },
  props: ['voices', 'isSpeaking'],
  data() {
    return {
      selectedVoice: null
    };
  },
  setup() {
    return {
      playOutline,
      pauseOutline,
      checkmarkOutline,
    };
  },
  computed: {
    ...HyacinthModule.mapGetters(["selectHyacinth"]),
    ...HyacinthModule.mapGetters(["selectHyacinth"]),
    ...SettingsModule.mapGetters(["selectUserSettings"]),

    voice: {
       get: function(){
         return this.hyacinth ? this.hyacinth.speechSynthesis.voice  : this.selectedVoice
       },
       // setters
       set: function(voice) {
        this.handleVoiceChanged(voice)
       }
    },
    settings() {
      return this.selectUserSettings;
    },
    hyacinth(){
      return this.selectHyacinth
    }
  },
  methods: {
    ...HyacinthModule.mapActions(['changeHyacinthProperty', 'updateHyacinthSettings']),
    
    testVoice(){
      this.$emit('startVoiceTesting')
    },
    displayName(voice){
      if(this.hyacinth){
        const { name, lang  } = this.hyacinth.speechSynthesis.voice
        const currentVoice = ( voice.name === name && voice.lang === lang )
        return currentVoice ? `${voice.name} ( ${voice.lang} ) selected` : `${voice.name} ( ${voice.lang} )`
      }
      else{
        return voice.name
      }
    },
    handleVoiceChanged(voice){
      this.selectedVoice = voice
      this.$emit('voiceSelected', voice)
    },
    async updateHyacinthSelectedVoice(){
      const voice = this.voices.find(v => v.name === this.selectedVoice.name)

      if(voice){

        const payload = {
          speechSynthesis: {
            voice: voice
          }
        }

        // await this.updateHyacinthSettings(payload)
      }

    },
  },
  mounted() {
    if(this.hyacinth){
      const { voice } = this.hyacinth.speechSynthesis
      this.selectedVoice = this.voices.find(v => v.name === voice.name )
    }
  }
};
</script>

<style scoped lang="scss">
  .voice-change-icon{
    font-size: 32px;
    margin: auto;
  }
  .voice-change-icon:hover{
    transform: translateY(-3px);
    transform: translateX(-3px);
  }
</style>
