<template>
  <div class="marvel-device iphone-x">
    <div class="notch">
      <div class="camera"></div>
      <div class="speaker"></div>
    </div>
    <div class="top-bar"></div>
    <div class="sleep"></div>
    <div class="bottom-bar"></div>
    <div class="volume"></div>
    <div class="overflow">
      <div class="shadow shadow--tr"></div>
      <div class="shadow shadow--tl"></div>
      <div class="shadow shadow--br"></div>
      <div class="shadow shadow--bl"></div>
    </div>
    <div class="inner-shadow"></div>
    <div class="screen">
      <div class="carrier mt-3 text-dark">
        <span style="margin-left: -200px">
          <i class="fa fa-signal"></i> T-Mobile
        </span>
        <span class="right" style="float: right; margin-right: 45px">
          <ion-icon class="phone-icon" :icon="cellularOutline"></ion-icon>
          {{ "   "
          }}<ion-icon class="phone-icon" :icon="batteryHalfOutline"></ion-icon>
        </span>
      </div>
      <!-- carrier section end -->
      <div class="phone-content mt-4">
        <ion-list lines="none">
          <ion-radio-group>
            <ion-item class="row mb-3">
              <div class="col-md-12">
                <div
                  class="card lightModecard"
                  ref="lightModecard"
                  @click.prevent="handleDarkModeChange('light')"
                >
                  <div class="card-body">
                    <ion-item>
                      <ion-label>Light</ion-label>
                      <ion-radio
                        slot="end"
                        color="success"
                        value="light"
                        mode="ios"
                        :checked="isChecked.light"
                      ></ion-radio>
                    </ion-item>
                    <ion-item>
                      <ion-thumbnail slot="start">
                        <ion-skeleton-text></ion-skeleton-text>
                      </ion-thumbnail>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text
                            style="width: 80%"
                          ></ion-skeleton-text>
                        </h3>
                        <p>
                          <ion-skeleton-text
                            style="width: 90%"
                          ></ion-skeleton-text>
                        </p>
                      </ion-label>
                    </ion-item>
                  </div>
                </div>
              </div>
            </ion-item>
            <ion-item class="row mb-3">
              <div class="col-md-12">
                <div
                  class="card darkModecard"
                  ref="darkModecard"
                  @click.prevent="handleDarkModeChange('dark')"
                >
                  <div class="card-body">
                    <ion-item>
                      <ion-label>Dark</ion-label>
                      <ion-radio
                        slot="end"
                        value="dark"
                        mode="ios"
                        color="success"
                        :checked="isChecked.dark"
                      ></ion-radio>
                    </ion-item>
                    <ion-item>
                      <ion-thumbnail slot="start">
                        <ion-skeleton-text></ion-skeleton-text>
                      </ion-thumbnail>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text
                            style="width: 60%"
                          ></ion-skeleton-text>
                        </h3>
                        <p>
                          <ion-skeleton-text
                            style="width: 90%"
                          ></ion-skeleton-text>
                        </p>
                      </ion-label>
                    </ion-item>
                  </div>
                </div>
              </div>
            </ion-item>
            <ion-item class="row">
              <div class="col-md-12">
                <div
                  class="card autoModecard"
                  ref="autoModecard"
                  @click.prevent="handleDarkModeChange('auto')"
                >
                  <div class="card-body">
                    <ion-item>
                      <ion-label>Auto</ion-label>
                      <ion-radio
                        slot="end"
                        color="success"
                        value="auto"
                        mode="ios"
                        :checked="isChecked.auto"
                      ></ion-radio>
                    </ion-item>
                    <ion-item>
                      <ion-thumbnail slot="start">
                        <ion-skeleton-text></ion-skeleton-text>
                      </ion-thumbnail>
                      <ion-label>
                        <h3>
                          <ion-skeleton-text
                            style="width: 80%"
                          ></ion-skeleton-text>
                        </h3>
                        <p>
                          <ion-skeleton-text
                            style="width: 90%"
                          ></ion-skeleton-text>
                        </p>
                      </ion-label>
                    </ion-item>
                  </div>
                </div>
              </div>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>
    <!-- screen section end -->
  </div>
</template>

<script>
import { 
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonRadio,
  IonThumbnail,
  IonRadioGroup,
  IonSkeletonText,} from "@ionic/vue";
import { 
  moonOutline,
  layersOutline,
  cellularOutline,
  locationOutline,
  batteryHalfOutline,
  ellipsisVerticalOutline,
  informationCircleOutline,
  ellipsisHorizontalOutline,
} from "ionicons/icons";
import {
  createNamespacedHelpers 
} from "vuex";

const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "iPhonexDevice",
  data() {
    return {
      isLightModeChecked: false,
      isDarkModeChecked: false,
      isAutoModeChecked: false,
    };
  },
  setup() {
    return {
      moonOutline,
      layersOutline,
      cellularOutline,
      locationOutline,
      batteryHalfOutline,
      ellipsisVerticalOutline,
      informationCircleOutline,
      ellipsisHorizontalOutline,
    };
  },
  computed: {
    ...SettingsModule.mapGetters([
      "selectUserSettings",
      "selectApplicationSettings",
    ]),

    application() {
      return this.selectApplicationSettings;
    },
    settings() {
      return this.selectUserSettings;
    },
    isChecked() {
      return {
        dark: this.isDarkModeChecked,
        auto: this.isAutoModeChecked,
        light: this.isLightModeChecked,
      };
    },
  },
  methods: {
    ...SettingsModule.mapActions(["saveDarkModeSettings"]),
    
    handleDarkModeChange(value) {
      this.applyStyling(value);
      this.updateSystemSettings(value);
    },
    async updateSystemSettings(value) {
      await this.saveDarkModeSettings(value);
    },
    applyStyling(value) {
      switch (value) {
        case "light":
          this.isLightModeChecked = true;
          this.isDarkModeChecked = false;
          this.isAutoModeChecked = false;
          this.$refs.lightModecard.style.border = "1px solid blue";
          // this.$refs.darkModecard.style.border = 'none'
          // this.$refs.autoModecard.style.border = 'none'
          break;
        case "dark":
          this.isDarkModeChecked = true;
          this.isLightModeChecked = false;
          this.isAutoModeChecked = false;
          this.$refs.darkModecard.style.border = "1px solid blue";
          // this.$refs.lightModecard.style.border = 'none'
          // this.$refs.autoModecard.style.border = 'none'
          break;
        case "auto":
          this.isAutoModeChecked = true;
          this.isDarkModeChecked = false;
          this.isLightModeChecked = false;
          this.$refs.autoModecard.style.border = "1px solid blue";
          // this.$refs.lightModecard.style.border = 'none'
          // this.$refs.darkModecard.style.border = 'none'
          break;
        default:
          return;
      }
    },
  },
  components: {
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonRadio,
    IonThumbnail,
    IonRadioGroup,
    IonSkeletonText,
  },
  mounted() {
    this.applyStyling(this.application.theme);
  }
};
</script>
<style scoped lang="scss">
  .phone-icon {
    font-size: 18px;
    margin: auto;
  }
  .iphone-x {
    margin-top: -290px;
    bottom: -290px;
  }
  .lightModecard:hover,
  .darkModecard:hover,
  .autoModecard:hover {
    transform: translateY(-3px);
    opacity: 1;
  }
  .lightModecard,
  .darkModecard,
  .autoModecard {
    transition: 0.2s;
  }
  .darkModecard .card-body {
    background-color: #242526;
    color: white;
    border: 1px solid #242526;
  }
  .darkModecard ion-item {
    --background: #242526;
    --color: white;
  }
  .darkModecard ion-item ion-thumbnail ion-skeleton-text {
    --background: #383a3b;
  }
  .darkModecard ion-item ion-label h3,
  .darkModecard ion-item ion-label ion-skeleton-text {
    background-color: #383a3b;
  }
  /*======================  auto mode card section =========================*/
  .autoModecard .card-body {
    background: linear-gradient(#242526, white);
    color: white;
  }
  .autoModecard ion-item {
    --background: linear-gradient(#242526);
    --color: white;
  }

  /*======================  light mode card section =========================*/
  .lightModecard .card-body {
    background: linear-gradient(#e5e7eb, white);
    color: black;
  }
  .lightModecard ion-item {
    --background: linear-gradient(#242526);
    --color: black;
  }
  .lightModecard ion-item ion-thumbnail ion-skeleton-text {
    --background: rgb(230, 225, 225);
  }
  .lightModecard ion-item ion-label h3,
  .lightModecard ion-item ion-label ion-skeleton-text {
    background-color: rgb(230, 225, 225);
  }
</style>
