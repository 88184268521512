<template>
  <div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <div class="card menu-position"
          :style="isChecked.left ? {border: '1px solid blue'}: ''"
          @click.prevent="handleMenuPositionSelection('left')">
          <div class="card-body">
            <div class="card-header">
              <ion-buttons slot="start">
                <ion-menu-button :color="isChecked.left ? 'primary' : 'medium'" auto-hide="false"></ion-menu-button>
              </ion-buttons>
            </div>
            <h3 :class="`bg-${isChecked.left ? 'primary' : 'secondary'}`">
              <ion-skeleton-text style="width: 50%"></ion-skeleton-text>
            </h3>
            <h3 :class="`bg-${isChecked.left ? 'primary' : 'secondary'}`">
              <ion-skeleton-text style="width: 80%"></ion-skeleton-text>
            </h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card menu-position" 
          :style="isChecked.right ? {border: '1px solid blue'}: ''"
          @click.prevent="handleMenuPositionSelection('right')">
          <div class="card-body">
            <div class="card-header text-right">
              <ion-buttons style="margin-left: 140px">
                <ion-menu-button :color="isChecked.right ? 'primary' : 'medium'" auto-hide="false"></ion-menu-button>
              </ion-buttons>
            </div>
            <div class="box">
              <h3 :class="`bg-${isChecked.right ? 'primary' : 'secondary'}`">
                <ion-skeleton-text style="width: 50%"></ion-skeleton-text>
              </h3>
              <h3 :class="`bg-${isChecked.right ? 'primary' : 'secondary'}`">
                <ion-skeleton-text style="width: 80%"></ion-skeleton-text>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonSkeletonText, IonButtons, IonMenuButton } from "@ionic/vue"
import { expandOutline } from 'ionicons/icons'

export default {
  name: "MenuItemPosition",
  props: ['settings', 'application'],
  components: {
    IonButtons,
    IonMenuButton,
    IonSkeletonText,
  },
  data() {
    return {
      isPositionedLeft: null,
      isPositionedRight: null,
    }
  },
  setup(){
    return {
      expandOutline
    }
  },
  computed: {
    isChecked(){
      return {
        left: this.isPositionedLeft,
        right: this.isPositionedRight
      }
    }
  },
  methods: {
    applyStylingProperties(value){
      switch (value){
        case 'left':
          this.isPositionedRight = false
          this.isPositionedLeft = true
          break
        
        case 'right':
          this.isPositionedRight = true
          this.isPositionedLeft = false
          break
        
        default:
          // ignore
      }
    },
    handleMenuPositionSelection(position){
      this.applyStylingProperties(position)
      this.$emit('menuPositionSelection', position)
    }
  },
  mounted() {
    this.applyStylingProperties(this.application.menuPosition)
  }
};
</script>

<style scoped lang="scss">
  .menu-position:hover {
    cursor: pointer;
    transform: translateY(-5px);
    opacity: 5;
  }
</style>
