<template>
  <div class="card" :style="settings.fg">
    <div class="card-body">
      <h4 :class="application.theme === 'light' ? 'text-muted' : ''">
        Charts
      </h4>
      <ion-list lines="none" :style="settings.fg">
        <div class="row">
          <div class="col-md-4">
            <ion-item :style="settings.fg" class="mt-1">
              <div>
                Allow fetching <br />
                <small class="text-muted">
                  Enable or disable continuous fetching of chart data per interval
                  settings below.
                </small>
                <div>
                  <ion-toggle 
                  :checked="isChecked"
                  value="true"
                  @ionChange.prevent="updateContinuousFetching" 
                  class="mt-2" mode="ios" color="primary"></ion-toggle>
                </div>
              </div>
            </ion-item>
          </div>
          <div class="col-md-4">
            <ion-item :style="settings.fg" class="mt-1">
              <div>
                Fetch interval <br />
                <small class="text-muted">
                  Changing this setting will change how often the report data for chart are
                  pulled.
                </small>
                <div>
                  <select
                    @input="updateFetchInterval"
                    v-model="fetchInterval"
                    class="form-select form-control mt-2"
                    aria-label="Default select example">
                    <option selected>Select interval</option>
                    <option value="*/1 * * * *">Every minute</option>
                    <option value="*/5 * * * *">Every 5 minutes</option>
                    <option value="*/15 * * * *">Every 15 minutes</option>
                    <option value="*/30 * * * *">Every 30 minutes</option>
                  </select>
                </div>
              </div>
            </ion-item>
          </div>
          <div class="col-md-4">
            <ion-item :style="settings.fg" class="mt-1">
              <div>
                Color palette <br />
                <small class="text-muted">
                  Changing the palette will also update the text and background colors of the chart.
                  Checkout the available options.
                </small>
                <ColorPaletteSector
                @updateDefaultPalette="updateDefaultPalette"/>
              </div>
            </ion-item>
          </div>
        </div>
      </ion-list>
    </div>
  </div>
</template>

<script>
import {IonList, IonItem, IonToggle } from '@ionic/vue'
import { createNamespacedHelpers } from 'vuex'
import ColorPaletteSector from './ColorPaletteSector.vue'
const LoaderModule = createNamespacedHelpers('loaders')
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: 'ChartSection',
  props: ['settings', 'application'],
  components: {
    IonList, IonItem, IonToggle, ColorPaletteSector
  },
  data(){
    return {
      isChecked: false,
      fetchInterval: null,
    }
  },
  methods: {
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
    ...SettingsModule.mapActions([
      'saveDefaultColorPalette',
      'saveFetchIntervalSettings',
      'saveContinuousFetchingSettings'
    ]),

    async updateFetchInterval(event) {
      this.addToLoaders('save-interval-settings')
      await this.saveFetchIntervalSettings(event.target.value)
      this.removeFromLoaders('save-interval-settings')
    },
    async updateDefaultPalette(event) {
      this.addToLoaders('save-color-palette')
      await this.saveDefaultColorPalette(event.target.value)
      this.removeFromLoaders('save-color-palette')
    },
    async updateContinuousFetching(event) {
      this.addToLoaders('save-fetch-settings')
      await this.saveContinuousFetchingSettings(event.target.value)
      this.removeFromLoaders('save-fetch-settings')
    },
  },
  mounted() {
    if(this.application.chart.fetch){
      this.isChecked = true
    }

    if(this.application.chart.fetchInterval){
      this.fetchInterval = this.application.chart.fetchInterval
    }
  }
}
</script>
