<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="system-settings-wrapper mt-4">
        <div class="container">
          <div class="row mb-4">
            <div class="col-md-12 chart-section mb-4">
              <ChartSectionMain 
              :settings="settings" 
              :application="application"
              />
            </div>

            <!-- <div class="col-md-6 chart-section mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <h4 :class="application.theme === 'light' ? 'text-muted' : ''">
                    App
                  </h4>
                  <ion-list lines="none" :style="settings.fg">
                    <ion-item :style="settings.fg" class="mt-1">
                      <div>
                          Fetch interval <br />
                          <small class="text-muted">
                            Changing this setting will change how often the report data are pulled.
                          </small>
                        </div>
                    </ion-item>
                  </ion-list>
                </div>
              </div>
            </div>

            <div class="col-md-6 chart-section mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <h4 :class="application.theme === 'light' ? 'text-muted' : ''">
                    App
                  </h4>
                  <ion-list lines="none" :style="settings.fg">
                    <ion-item :style="settings.fg" class="mt-1">
                      <div>
                          Fetch interval <br />
                          <small class="text-muted">
                            Changing this setting will change how often the report data are pulled.
                          </small>
                        </div>
                    </ion-item>
                  </ion-list>
                </div>
              </div>
            </div> -->

            <div class="col-md-12 appearance-section mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <h4 :class="application.theme === 'light' ? 'text-muted' : ''">
                    Appearance
                  </h4>
                  <ion-list lines="none" :style="settings.fg">
                    <div class="row">
                      <div class="col-md-6 mb-4 mt-4 appearance-container">
                        <ion-item :style="settings.fg" class="appearance-item2">
                          <!-- <ion-icon 
                            class="dark-mode-icon text-muted"
                            :icon="moonOutline">
                          </ion-icon> -->
                          <div>
                            Theme settings <br />
                            <small class="text-muted">
                              Changing the theme sittings will effect the
                              appearance of the application.
                              <hr>
                            </small>
                            <span>
                              <p class="text-muted"> Locate the phone device to the right or below to change this setting.</p>
                            </span>
                          </div>
                          
                        </ion-item>
                        <ion-item :style="settings.fg" class="appearance-item1">
                          <!-- <ion-icon 
                            class="dark-mode-icon text-muted"
                            :icon="moonOutline">
                          </ion-icon> -->
                          <div>
                            Menu position <br />
                            <small class="text-muted">
                              Changing this menu setting will effect the 
                              placement of the menu toggle button and its contents.
                              <hr>
                            </small> <br>
                            <div>
                              <MenuItemPosition 
                              :settings="settings"
                              :application="application"
                              @menuPositionSelection="handleMenuPositionSelection"/>
                            </div>
                          </div>
                        </ion-item>
                      </div>
                      <!-- left side end -->
                      <div class="col-md-6 mb-4 mt-4 text-center">
                        <iPhonexDevice></iPhonexDevice>
                      </div>
                      <!-- right side end -->
                    </div>
                  </ion-list>
                </div>
              </div>
            </div>
            <div class="col-md-12 hyacinth-section mb-4">
              <div class="card" :style="settings.fg">
                <div class="card-body">
                  <h4 :class="application.theme === 'light' ? 'text-muted' : ''">
                    Hyacinth
                  </h4>
                  <ion-list lines="none" :style="settings.fg">
                    <div class="row">
                      <div class="col-md-6 mb-4 mt-4">
                        <ion-item :style="settings.fg">
                          <ion-icon 
                            class="dark-mode-icon text-muted"
                            :icon="layersOutline">
                          </ion-icon>
                          <div>
                            Voice to Text & Speech Recognition <br />
                            <small class="text-muted">
                              Changing settings under this card will effect
                              how Hyacinth respondes to your requests.
                            </small>
                          </div>
                        </ion-item>
                        <hr />
                        <ion-list :style="settings.fg">
                          <ion-item :style="settings.fg">
                            <ion-icon 
                            class="settings-icons text-muted"
                            :icon="languageOutline">
                            </ion-icon> 
                            <ion-label>Language <br> 
                            <small class="text-muted">
                              this interface sets the language of the response
                            </small></ion-label>
                            <ion-select
                              v-model="languages"
                              placeholder="Select One" 
                              interface="popover" mode="ios">
                              <ion-select-option 
                                v-for="lang in languagesList" 
                                :key="lang.name"  
                                :value="lang">{{ lang.name }}
                              </ion-select-option>
                            </ion-select>
                          </ion-item>

                          <ion-item :style="settings.fg">
                            <ion-icon
                            class="settings-icons text-muted"
                            :icon="infiniteOutline"></ion-icon> 
                            <ion-label>Continuous <br> 
                            <small class="text-muted">
                              this interface sets the recognition process to be continuous
                            </small></ion-label>
                            <ion-select
                              @ionChange.stop="(event) => handlePropertyChange({ speech: { continuous: event.target.value }})"
                              v-model="continuous"
                              placeholder="Select One" interface="popover" mode="ios">
                              <ion-select-option value="true">true</ion-select-option>
                              <ion-select-option value="false">false</ion-select-option>
                            </ion-select>
                          </ion-item>

                          <ion-item :style="settings.fg">
                            <ion-icon 
                            class="settings-icons text-muted"
                            :icon="gitBranchOutline">
                            </ion-icon> 
                            <ion-label>Alternatives <br> 
                            <small class="text-muted">
                              this interface sets the maximum number of recognition results
                            </small></ion-label>
                            
                            <ion-select
                              @ionChange.stop="(event) => handlePropertyChange({ speech: { maxAlternatives: event.target.value }})"
                              v-model="maxAlternatives"
                              placeholder="Select One" interface="popover" mode="ios">
                              <ion-select-option value="1">1</ion-select-option>
                              <ion-select-option value="2">2</ion-select-option>
                              <ion-select-option disabled value="3">3</ion-select-option>
                            </ion-select>
                          </ion-item>

                          <ion-item :style="settings.fg">
                            <ion-icon
                            class="settings-icons text-muted"
                            :icon="unlinkOutline"></ion-icon> 
                            <ion-label>Interim results <br> 
                            <small class="text-muted">
                              this interface controls whether interim results should be returned
                            </small></ion-label>
                            <ion-select
                            v-model="interimResults"
                            @ionChange.stop="(event) => handlePropertyChange({ speech: { interimResults: event.target.value }})"
                            placeholder="Select One" interface="popover" mode="ios">
                              <ion-select-option value="true">true</ion-select-option>
                              <ion-select-option value="false">false</ion-select-option>
                            </ion-select>
                          </ion-item>
                          
                          <ion-item :style="settings.fg">
                            <ion-icon 
                            class="settings-icons text-muted"
                            :icon="speedometerOutline">
                            </ion-icon> 
                            <ion-label>Speech Rate <br> 
                            <small class="text-muted">
                              this interface sets the speed on how fast hyacinth speaks
                            </small></ion-label>
                            <ion-select
                              v-model="speechRate"
                              @ionChange.stop="(event) => handlePropertyChange({ speechSynthesis: { rate: event.target.value }})"
                              placeholder="Select One" interface="popover" mode="ios">
                              <ion-select-option 
                              v-for="rate in speechRateList" 
                              :key="rate.value" :value="rate.value">{{ rate.name }} </ion-select-option>
                            </ion-select>
                          </ion-item>

                        </ion-list>
                      </div>
                      <div class="col-md-6 mb-4">
                        <ion-item class="mt-4" :style="settings.fg">
                            <ion-icon 
                              class="dark-mode-icon text-muted"
                              :icon="earOutline">
                            </ion-icon> 
                            <div>
                              Change spoken voice & language <br />
                              <small class="text-muted">
                                test the current agent's spoken voice and assign a new voice and language
                              </small>
                            </div>
                        </ion-item>
                        <hr>
                        <ion-item :style="settings.fg">
                          <VoiceChanger class="mt-3" 
                          :hyacinth="hyacinth"
                          @voiceSelected="handleVoiceSelected"
                          :voices="voices"
                          :isSpeaking="isSpeaking"
                          @startVoiceTesting="speak"/>
                        </ion-item>
                      </div>
                    </div>
                  </ion-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "@/router";
import iPhonexDevice from "../../devices/iPhonexDevice.vue"
import VoiceChanger from "./hyacinth/VoiceChange.vue"
import ChartSectionMain from './sections/chart-section/ChartSectionMain.vue'
import MenuItemPosition from './miscellaneous/MenuItemPosition.vue'

import {
  playOutline,
  moonOutline,
  unlinkOutline,
  layersOutline,
  earOutline,
  infiniteOutline,
  languageOutline,
  gitBranchOutline,
  checkmarkOutline,
  speedometerOutline,
} from "ionicons/icons";
import { mapActions, createNamespacedHelpers } from "vuex";
import {
  IonPage,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonHeader,
  IonSelect,
  IonContent,
  IonToolbar,
  IonSelectOption,
} from "@ionic/vue";
const SettingsModule = createNamespacedHelpers("setting");
const HyacinthModule = createNamespacedHelpers('hyacinth')
const SpeechRecognitionModule = createNamespacedHelpers("speechRecognition")

export default {
  name: "SystemSettings",
  components: {
    IonPage,
    IonIcon,
    IonList,
    IonLabel,
    IonItem,
    IonSelect,
    IonHeader,
    IonContent,
    IonToolbar,
    VoiceChanger,
    iPhonexDevice,
    IonSelectOption,
    MenuItemPosition,
    ChartSectionMain,
  },
  data() {
    return {
      languagesList: [
        {
          name: 'English (US)',
          value: 'en-US'
        },
        {
          name: 'English (UK)',
          value: 'en-UK'
        },
        {
          name: 'Spanish (Mexico)',
          value: 'es-MX'
        }
      ],
      speechRateList: [
        {
          name: '0.9 slower',
          value: 0.9
        },
        {
          name: '1 normal',
          value: 1
        },
        {
          name: '1.5 fast',
          value: 1.5
        },
        {
          name: '2 faster',
          value: 2
        }
      ],
      speechRecognition: {
        rate: 1,
        language: null,
        continuous: false,
        interimResult: false,
        maxAlternatives: 1,
      },
      isLoading: true,
    };
  },
  setup() {
    return {
      playOutline,
      earOutline,
      moonOutline,
      unlinkOutline,
      layersOutline,
      infiniteOutline,
      languageOutline,
      gitBranchOutline,
      speedometerOutline,
      checkmarkOutline,
      // musicalNotesOutline,
    };
  },
  computed: {
    ...SettingsModule.mapGetters([
      "selectUserSettings",
      "selectApplicationSettings",
    ]),
    ...HyacinthModule.mapGetters(['selectHyacinth']),
    ...SpeechRecognitionModule.mapGetters(['selectRecognition', 'selectVoiceList', 'selectIsSpeaking']),

    voices(){
      return this.selectVoiceList.filter(voice => voice.lang.includes('en-'))
    },
    settings() {
      return this.selectUserSettings;
    },
    hyacinth() {
      return this.selectHyacinth
    },
    application() {
      return this.selectApplicationSettings;
    },
    recognition() {
      return this.selectRecognition
    },
    isSpeaking(){
      return this.selectIsSpeaking
    },
    languages: {
      // getter
      get: function(){
        return this.speechRecognition.language 
      },
      // setter
      set: function(value) {
        this.speechRecognition.language = value
      }
    },
    continuous: {
      // getter
      get: function(){
        return this.speechRecognition.continuous.toString()
      },
      // setter
      set: function(value) {
        this.speechRecognition.continuous = value
      }
    },
    speechRate: {
      // getter
      get: function(){
        return this.speechRecognition.rate
      },
      // setter
      set: function(value) {
        this.speechRecognition.rate = value
      }
    },
    interimResults: {
      // getter
      get: function(){
        return this.speechRecognition.interimResult.toString()
      },
      // setter
      set: function(value) {
        this.speechRecognition.interimResult = value
      }
    },
    maxAlternatives: {
      // getter
      get: function(){
        return this.speechRecognition.maxAlternatives.toString()
      },
      // setter
      set: function(value) {
        this.speechRecognition.maxAlternatives = value
      }
    },
  },
  methods: {
    ...mapActions(["changePageTitle", "navigateToOption"]),
    ...SpeechRecognitionModule.mapActions([
      'speak',
      'setSelectedVoice', 
      'startRecognition', 
      'fetchSpeechRecognitionSettings',
    ]),
    ...HyacinthModule.mapActions(['changeHyacinthProperty']),
    ...SettingsModule.mapActions([ 'saveMenuPositionSettings' ]),

    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },
    handleVoiceSelected(voice){
      this.setSelectedVoice(voice)
    },
    handlePropertyChange(event) {
      if(!this.isLoading){
        this.changeHyacinthProperty(event)
      }
    },
    async handleMenuPositionSelection(position){
      await this.saveMenuPositionSettings(position)
    }
  },
  async mounted() {
    this.setPageTitle();
    await this.fetchSpeechRecognitionSettings()
    // this.speechRecognition.rate = this.hyacinth.speechSynthesis.rate
    // this.speechRecognition.language = this.languagesList.find(lang => lang.value === this.hyacinth.speech.lang ) 
    // this.speechRecognition.continuous = this.hyacinth.speech.continuous
    // this.speechRecognition.interimResult = this.hyacinth.speech.interimResults
    // this.speechRecognition.maxAlternatives = this.hyacinth.speech.maxAlternatives
    
  },
  created(){
    this.isLoading = false
  }
};
</script>
<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 12%;
  transform: translateY(-50%);
}

.dark-mode-icon {
  font-size: 32px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.system-settings-wrapper {
  display: flex;
  flex-direction: row;
}

.settings-icons {
  font-size: 32px;
  margin-right: 10px;
}

.appearance-container {
  display: flex;
  flex-direction: column;
}

// reorder this card on the smaller screen
@media screen and (max-width: 768px) {
  .dark-mode-icon {
    font-size: 64px;
  }
  .system-settings-wrapper .hyacinth-section {
    order: 0;
  }
  .system-settings-wrapper .appearance-section {
    order: 1;
  }
  .appearance-container .appearance-item1 {
      order: 0;
  }
  .appearance-container .appearance-item2 {
      order: 1;
  }
}
</style>
